<template>
  <Teleport v-if="isReady" :to="to" :disabled="disabled">
    <slot />
  </Teleport>
</template>

<script setup lang="ts">
import { nextTick, onMounted } from "#imports";

const props = defineProps<{
  to: string | HTMLElement;
  timeout?: number;
  disabled?: boolean;
  nextTick?: boolean;
}>();

const isReady = ref(false);

onMounted(async () => {
  if (props.nextTick) {
    await nextTick();
  }
  if (props.timeout !== undefined) {
    await new Promise((resolve) => {
      setTimeout(resolve, props.timeout);
    });
  }
  isReady.value = true;
});
</script>
